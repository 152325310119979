<template>
  <!-- SPORT type1 -->
  <div
    :id="'menu-' + data.type + '-type1'"
    class="mb-3"
    :class="data.type"
  >
    <!-- header -->
    <div class="sport__header d-flex align-center px-4">
      <div class="sport__header--img">
        <!-- SPORT ICON -->
        <Icon
          v-if="data.type === 'sport'"
          data="@icon/block/game/type2/sport.svg"
          width="18"
          height="18"
          class="title--text"
        />
        <!-- ESPORT ICON -->
        <Icon
          v-else
          data="@icon/block/game/type2/esport.svg"
          width="18"
          height="18"
          class="title--text"
        />
      </div>
      <span class="sport__header--title font-weight-bold ml-2 title--text">
        {{ data.type.toUpperCase() }}
      </span>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 mt-2"
      >
        <swiper-slide
          v-for="game in gameItem"
          :key="game._id"
          class="game__item p-relative"
        >
          <div
            class="game cursor-pointer rounded"
            :style="gradientBg"
            @click="lauchGame(game.game_code)"
          >
            <!-- IMG -->
            <div class="game__img">
              <v-img
                :src="baseImgUrl + game.image_h5_2"
                height="96"
                class="rounded"
              />
            </div>

            <!-- NAME -->
            <div class="subtitle-2 white--text game__title p-absolute">
              {{ game.title }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import hexToRgba from 'hex-to-rgba'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 1.1,
      spaceBetween: 8,
      centeredSlides: true,
      centeredSlidesBounds: true,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),

    gameItem() {
      return this.data.data.navbar[0].items
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

    vendorBg() {
      return `background:linear-gradient(${hexToRgba(this.primaryColor)}, ${hexToRgba(this.primaryColor, '.3')});`
    },
  },

  methods: {
    /**
     * 啟動遊戲
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    lauchGame(gameCode) {
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sport__header--title {
	font-size: 18px;
}

.game__title {
	top: 50%;
	left: 16px;
	margin-top: -12px;
}

</style>
